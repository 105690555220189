import { ECommunicationMethod, ISODateString, User as SharedM7User } from "@m7-health/shared-utils";

import { IUnitBasic } from "~/routes/api/types";

import { APIElementResponse, ApiEmptyResponse, APIListResponse, SDTO } from "../_shared/types";
import { IStaffCategory } from "../staffCategory";
import { IStaffDetails } from "../staffDetails";

export type IUser = User.DTO;

export namespace User {
  export enum ERole {
    admin = "admin",
    scheduler = "scheduler",
    staff = "staff",
    kiosk = "kiosk",
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    unitIds: IUnitBasic["id"][];
    roles: ERole[];
    isSoftDeleted?: boolean;
    softDeletedDate?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    lastLoggedInAt: ISODateString | null;
    onboardingStatus: SharedM7User.EOnboardingStatus;
  }

  export namespace API {
    export const ROOT_PATH = "/user";

    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnitBasic["id"][];
        ids?: IUser["id"][];
        roles?: DTO["roles"];
        staffCategories?: IStaffCategory["key"][];
        suspended?: boolean[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace GetNotificationsCount {
      export const PATH = `${ROOT_PATH}/notifications-count`;
      export interface QueryParams {
        version: "2";
      }

      export type Response = APIElementResponse<{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        OPSRequestCount: number;
        approvalRequestCount: number;
      }>;
    }

    export namespace SendResetPassword {
      export const PATH = `${ROOT_PATH}/trigger-reset-password`;
      export interface QueryParams {
        userIds: IUser["id"][];
        preferredCommunicationMethod: ECommunicationMethod;
      }

      export type Response = ApiEmptyResponse;
    }

    export namespace BulkUploadUsers {
      export const PATH = `${ROOT_PATH}/bulk-upload`;
      export interface QueryParams {
        users: CreateStaffUserDTO[];
        sendResetPasswordMethod: ECommunicationMethod | null;
      }

      export type Response = ApiEmptyResponse;
    }
  }
}

type CreateStaffUserDTO = Pick<
  IUser & IStaffDetails,
  | "email"
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "staffTypeKey"
  | "preceptor"
  | "onOrientation"
  | "orientationEndDate"
  | "employmentType"
  | "contractEndDate"
  | "employmentStartDate"
  | "shiftType"
  | "homeUnitId"
  | "roles"
>;
